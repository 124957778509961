import { hasData } from "@/lib/utils/hasData";
import toast from "react-simple-toasts";
import { markDownReact } from "../markDown";

//LIKES

export const handlers = (ssr_data_tribe) => {
  const likeHandler = async (chat_id, chatter_id, my_id, host_id) => {
    if (hasData(chat_id)) {
      const res = await fetch(
        `/api/private/chat/like?id=${chat_id}&host=${host_id}`
      );
      const json = await res.json();
      if (json) {
        const { action } = json[0][0];

        //WEBSOCKET
        if (action?.toString() == "429") {
          toast(`Slow down! You move too fast. 🎼♩♬♫🎶🎵`, {
            time: 3000,
            className: "",
            clickable: true,
            clickClosable: false,
          });
        }

        return action;
      }
    }

    return -3;
  };

  //DELETES - MOVE THIS TO SEPARATE FILE AND HANDLE VIA API CALL
  const removeHandler = async (
    chat_id,
    host_id,
    isConfirmed,
    chat_body,
    user_name
  ) => {
    const confirmed = await isConfirmed({
      title: `Are you sure you want to delete this message?`,
      body: (
        <div>
          <div>{markDownReact(chat_body, ssr_data_tribe?.server_website)}</div>
          <div>
            {markDownReact(`by @${user_name}`, ssr_data_tribe?.server_website)}
          </div>
        </div>
      ),
    });

    if (confirmed) {
      if (hasData(chat_id)) {
        const res = await fetch(
          `/api/private/chat/delete?id=${chat_id}&host=${host_id}`
        );
        const json = await res.json();
        if (json) {
          const { action } = json[0][0];
          if (action?.toString() == "429") {
            toast(`Slow down! You move too fast. 🎼♩♬♫🎶🎵`, {
              time: 3000,
              className: "",
              clickable: true,
              clickClosable: false,
            });
          } else if (action?.toString() == "1") {
            toast(`Message deleted.`, {
              time: 3000,
              className: "",
              clickable: true,
              clickClosable: false,
            });
          }
        }
      }
    }
  };

  //SAVES
  const saveHandler = async (chat_id, my_id, host_id) => {
    if (hasData(chat_id)) {
      const res = await fetch(
        `/api/private/chat/save?id=${chat_id}&host=${host_id}`
      );
      const json = await res.json();
      if (json) {
        const { action } = json[0][0];
        //console.log(action)
        if (action?.toString() == "429") {
          toast(`Slow down! You move too fast. 🎼♩♬♫🎶🎵`, {
            time: 3000,
            className: "",
            clickable: true,
            clickClosable: false,
          });
        } else if (action >= 1) {
          toast(`Message has been saved.`, {
            time: 3000,
            className: "",
            clickable: true,
            clickClosable: false,
          });
        } else {
          toast(`Save removed.`, {
            time: 3000,
            className: "",
            clickable: true,
            clickClosable: false,
          });
        }
      }
    }
  };

  //KUDOS
  const kudosHandler = async (
    chat_id,
    host_id,
    isConfirmed,
    chat_body,
    user_name
  ) => {
    const confirmed = await isConfirmed({
      title: `Are you sure you want to give a kudos to this message?`,
      body: (
        <div>
          <div>{markDownReact(chat_body, ssr_data_tribe?.server_website)}</div>
          <div>
            {markDownReact(`by @${user_name}`, ssr_data_tribe?.server_website)}
          </div>
        </div>
      ),
    });

    if (confirmed) {
      if (hasData(chat_id)) {
        const res = await fetch(
          `/api/private/chat_kudos/insert?id=${chat_id}&host=${host_id}`
        );
        const json = await res.json();
        if (json) {
          const { action } = json[0][0];
          //console.log(action)
          if (action?.toString() == "429") {
            toast(`Slow down! You move too fast. 🎼♩♬♫🎶🎵`, {
              time: 3000,
              className: "",
              clickable: true,
              clickClosable: false,
            });
          } else if (action >= 1) {
            toast(`Kudos has been added.`, {
              time: 3000,
              className: "",
              clickable: true,
              clickClosable: false,
            });
          } else {
            toast(`Kudos removed.`, {
              time: 3000,
              className: "",
              clickable: true,
              clickClosable: false,
            });
          }
        }
      }
    }
  };

  //REPORTS
  const reportHandler = async (
    chat_id,
    my_id,
    host_id,
    manual_action,
    isConfirmed
  ) => {
    var confirmed =
      manual_action == -1
        ? true
        : await isConfirmed(`Are you sure you want to report this message?`);

    if (confirmed && hasData(chat_id)) {
      const res = await fetch(
        `/api/private/chat/report?id=${chat_id}&action=${manual_action}&host=${host_id}`
      );
      const json = await res.json();
      if (json) {
        //console.log("json",json)
        const { action } = json[0][0];
        //console.log(action)
        if (action?.toString() == "429") {
          toast(`Slow down! You move too fast. 🎼♩♬♫🎶🎵`, {
            time: 3000,
            className: "",
            clickable: true,
            clickClosable: false,
          });
        } else if (action >= 1) {
          toast(`Report submitted`, {
            time: 3000,
            className: "",
            clickable: true,
            clickClosable: false,
          });
        } else {
          toast(`Reports removed.`, {
            time: 3000,
            className: "",
            clickable: true,
            clickClosable: false,
          });
        }
      }
    }
  };

  const returnHandlers: any = {
    likeHandler,
    removeHandler,
    saveHandler,
    kudosHandler,
    reportHandler,
  };

  return returnHandlers;
};

export function updateJsonArray(action, json, id) {
  var varjson;

  //console.log("json",json);
  if (typeof json == "undefined") {
    varjson = [];
  } else {
    varjson = JSON.parse(json);
  }

  //add
  if (action == 1) {
    varjson.push(id?.toString());
  }

  //remove
  if (action == -1) {
    varjson = varjson.filter((x) => x?.toString() !== id?.toString());
  }

  //console.log("JSON.stringify(varjson)",JSON.stringify(varjson))

  return JSON.stringify(varjson);
}
